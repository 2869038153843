import React from "react"
import SEO from '../../components/seo'
import Layout from '../../containers/layout/layout'
import Section from '../../containers/services/ecommerce'

export default () => (
    <Layout headerLayout={2}>
        <SEO title="Atlasopen - E-Commerce Solutions and Development" description="We provide technology services to develop technologies in e-Commerce including e-Commerce development, Apps, Integrations and out of the box solutions to e-Commerce problems."/>
        {/*<Banner/>*/}
        {/*<About/>*/}
        <Section/>
        {/*<JoinOurTeam/>*/}
        {/*<Testimonial/>*/}
    </Layout>
)

