import React from 'react'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import { SectionTitleWrap } from "../../company/join-our-team/join-our-team.stc"
import { AboutSectionWrap, SectionInnerLeft, SectionInnerRight } from '../web-development/about.stc'
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import Fade from 'react-reveal/Fade';
import Button from "../../../components/shared/button";

//queries the serviceswebdata.json file
const Section = ({ HeadingStyle, SubHeadingStyle, Paragraph, HeadingStyleServies }) => {
    const servicesQueryData = useStaticQuery(graphql`
        query ServicesEcommerceDataQuery {
            servicesecommercedataJson(jsonId: {eq: "ecommerce-content"}) {
                ecommerceServices {
                    title
                    classId
                    link
                    description
                    tableHeader
                    tableData
                    buttonColor
                }
            }
        }    
    `)
    const serviceData = servicesQueryData.servicesecommercedataJson;
    const ecommerceServices = serviceData.ecommerceServices;
    // console.log(serviceData.ecommerceServices)

    // const databaseContent = ecommerceServices.map((ecommerceService, index) =>
    //     <div className="col-1 offset-1" key={index}>
    //         <a style={{color: "black"}} href={ecommerceService.link}>{ecommerceService.title}</a>
    //     </div>
    // );

    const content = ecommerceServices.map((ecommerceService, index) =>
        <div className="row services" key={index} id={ecommerceService.classId}>
            <SectionInnerLeft style={{ paddingBottom: 50 }}>
                <SectionTitleWrap style={{ paddingRight: 100 }}>
                    <Heading {...HeadingStyleServies}>{ecommerceService.title}</Heading>
                    {ecommerceService.description.map((textData, i) => <Text key={`story-text-${i}`} {...Paragraph}>{textData}</Text>)}
                </SectionTitleWrap>
            </SectionInnerLeft>
            <SectionInnerRight style={{ paddingTop: 30 }}>
                {ecommerceService.image ?
                    <Fade right>
                        <img src={ecommerceService.image.childImageSharp.fluid.src} alt={ecommerceService.title} />
                    </Fade>
                    :
                    <Fade right>
                        <SectionTitleWrap>
                            {ecommerceService.tableHeader && <Heading {...SubHeadingStyle}>{ecommerceService.tableHeader}</Heading>}
                            <div className="row">
                                {ecommerceService.tableData.map((textData, i) =>
                                    <div className="col-1" key={`story-text-${i}`}>
                                        <Text {...Paragraph}>{textData}</Text>
                                    </div>)
                                }
                            </div>
                            {ecommerceService.link && <Button style={{ background: ecommerceService.buttonColor && ecommerceService.buttonColor }}
                                className="button" as={Link} to={ecommerceService.link} layout={2}>Learn More <i className="ti-arrow-right"></i>
                            </Button>}
                        </SectionTitleWrap>
                    </Fade>
                }

            </SectionInnerRight>
        </div>
    );

    return (
        <AboutSectionWrap>
            <div className="row service-top-section">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading {...HeadingStyle}>E-Commerce and Online Shopping</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage className="ecommerce-header" src={"../../../../src/data/images/macweb.jpg"} alt="ecommerce header" />
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading style={{ marginTop: 50 }} {...HeadingStyleServies}>We get businesses selling online fast.</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>Online Marketplaces to Online Stores - We Develop So You Sell</h3>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            {/*<div className="row services">*/}
            {/*    <div className="col-4 offset-1">*/}
            {/*        <SectionInner>*/}
            {/*            <SectionTitleWrap>*/}
            {/*                <Heading {...SubHeadingStyle}>ECOMMERCE SERVICES</Heading>*/}
            {/*            </SectionTitleWrap>*/}
            {/*        </SectionInner>*/}
            {/*    </div>*/}
            {/*    {databaseContent}*/}
            {/*</div>*/}

            {content}

        </AboutSectionWrap>
    )
}
Section.propTypes = {
    HeadingStyle: PropTypes.object,
    SubHeadingStyle: PropTypes.object,
    Paragraph: PropTypes.object,
    HeadingStyleServies: PropTypes.object,
    ecommerceServicLeft: PropTypes.object
}

Section.defaultProps = {
    HeadingStyle: {
        fontSize: '60px',
        texttransform: 'capitalize',
        lineHeight: '60px',
        color: '#e4e4e4',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    },
    SubHeadingStyle: {
        fontSize: '30px',
        lineHeight: '30px',
        color: 'primary',
        fontweight: 300,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    Paragraph: {
        color: 'black',
        fontSize: '20px'
    },
    HeadingStyleServies: {
        fontSize: '50px',
        texttransform: 'capitalize',
        lineHeight: '50px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    }
}

export default Section

